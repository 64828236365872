import React from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";


function Contact() {
  const { t } = useTranslation();

  const [mailerState, setMailerState] = useState({
    nom: "",
    prenom:"",
    numero:"",
    subject:"",
    email: "",
    message: "",
  });
  function handleStateChange(e) {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }
  const submitEmail = async (e) => {
    e.preventDefault();
    console.log({ mailerState });
    const response = await fetch("http://localhost:3001/send", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ mailerState }),
    })
      .then((res) => res.json())
      .then(async (res) => {
        const resData = await res;
        console.log(resData);
        if (resData.status === "success") {
          alert(t("msg_envoie")
          );
        } else if (resData.status === "fail") {
          alert(t("msg_echec"));
        }
      })
      .then(() => {
        setMailerState({
          nom: "",
          prenom:"",
          email: "",
          numero:"",
          subject:"",
          message: "",
        });
      });
  };

  return (
    <footer className="contact" id="contact">
      <div className="container-contact">
        <div className="title">
          <h3 className="contact-title">{t("ct-title")}</h3>
        </div>
        <form onSubmit={submitEmail}>
          <input
            type="text"
            name="nom"
            placeholder={t("placeholder_name")}
            required
            id="nom"
            onChange={handleStateChange}
            value={mailerState.nom}
          />
          <input
            type="text"
            name="prenom"
            placeholder={t("placeholder_prenom")}
            required
            id="prenom"
            onChange={handleStateChange}
            value={mailerState.prenom}
          />
          <input
            type="email"
            name="email"
            placeholder={t("placeholder_mail")}
            required
            id="email"
            onChange={handleStateChange}
            value={mailerState.email}
          />
          <input
            type="number"
            name="numero"
            placeholder={t("placeholder_phone")}
            id="numero"
            onChange={handleStateChange}
            value={mailerState.numero}
          />
          <input
            type="text"
            name="subject"
            placeholder={t("placeholder_obj")}
            required
            id="subject"
            onChange={handleStateChange}
            value={mailerState.subject}
          />
          <textarea
            name="message"
            placeholder={t("placeholder_msg")}
            required
            id="message"
            defaultValue={""}
            onChange={handleStateChange}
            value={mailerState.message}
          />
          <button name="Button" type="submit">
            {t("btn-text")}
          </button>
        </form>
      </div>
      <div className="maps">
        <iframe
          title="map"
          className="frame"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2623.9418863701885!2d2.389148915555762!3d48.87838437928947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66dbf632c8cc5%3A0xb07d6c18f2b14b2b!2s18%20Rue%20de%20Crim%C3%A9e%2C%2075019%20Paris!5e0!3m2!1sfr!2sfr!4v1656013090505!5m2!1sfr!2sfr"
          width={450}
          height={350}
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
        />
        <div className="contact-text">
          <h2>Email :</h2>
          <p>contact@hydrax.eu</p>
        </div>
      </div>
    </footer>
  );
}
export default Contact;
