import React from "react";
import photoAB from "../images/Adnan_Barh.jpg";
import photoHS from "../images/HV_MK2.png";
import photoFC from "../images/François_Cottignies-modified.png"
import photoFL from "../images/Frédéric-Labeille-modified.png"
import photoSG from "../images/Serge-Gautier-modified.png"
import { useTranslation } from "react-i18next";

function Fondateur() {
  const { t } = useTranslation();
  return (
    <div className="fondateurs" id="fondateurs">
      <div className="titre-text">
        <h1>{t("fondateur-title")}</h1>
      </div>
      <div className="membres_liste">
        <div className="membre">
          <img src={photoAB} alt="photo d'Adnan Barhoumi" />
          <div className="text_on">
            <div className="descript">
              <h3>{t("fondateur-h3")}</h3>
              <p>{t("fondateur-p")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="titre-text">
        <h1>{t("c-s-title")}</h1>
      </div>
      <div className="membres_liste">
        <div className="membres">
          <div className="membre-cs">
            <img src={photoFC} alt="photo de François Cottignies" />
            <div className="text_on">
              <div className="descript">
                <h3>{t("c-s2-h3")}</h3>
                <div className="">
                  <h3>{t("c-s1-h4")}</h3>
                  <p>
                    {t("c-s2-p")}
                    <br />
                    {t("c-s2-p2")}
                    <br />
                    {t("c-s2-date")}
                    <br />
                    <br />
                    {t("c-s2-p3")}
                    <br />
                    <br />
                    {t("c-s2-p4")}
                    <br />
                    <br />
                    {t("c-s2-p5")}
                    <br />
                    {t("c-s2-p6")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="membres">
          <div className="membre-cs">
            <img src={photoFL} alt="photo de Frédéric Labeille" />
            <div className="text_on">
              <div className="descript">
                <h3>{t("c-s3-h3")}</h3>
                <div className="">
                  <h3>{t("c-s1-h4")}</h3>
                  <p>
                    {t("c-s3-p")}
                    <br />
                    {t("c-s3-p2")}
                    <br />
                    <br />
                    {t("c-s3-p3")}
                    <br />
                    {t("c-s3-p4")}
                    <br />
                    {t("c-s3-date")}
                    <br />
                    <br />
                    {t("c-s3-p5")}
                    <br />
                    {t("c-s3-p6")}
                    <br />
                    {t("c-s3-date2")}
                    <br />
                    <br />
                    {t("c-s3-p7")}
                    <br />
                    {t("c-s3-p8")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="membres">
          <div className="membre-cs">
            <img src={photoSG} alt="photo de Serge Gautier" />
            <div className="text_on">
              <div className="descript">
                <h3>{t("c-s1-h3")}</h3>
                <div className="">
                  <h3>{t("c-s1-h4")}</h3>
                  <p>
                    {t("c-s1-p")}
                    <br />
                    {t("c-s1-p2")}
                    <br />
                    {t("c-s1-date")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="membres">
          <div className="membre-cs">
            <img src={photoHS} alt="photo d'Hervé Soufflet" />
            <div className="text_on">
              <div className="descript">
                <h3>{t("c-s4-h3")}</h3>
                <div className="">
                  <h3>{t("c-s1-h4")}</h3>
                  <p>
                    {t("c-s4-p")}
                    <br />
                    {t("c-s4-date")}
                    <br />
                    <br />
                    {t("c-s4-p2")}
                    <br />
                    {t("c-s4-date2")}
                    <br />
                    <br />
                    {t("c-s4-p3")}
                    <br />
                    {t("c-s4-p4")}
                    <br />
                    {t("c-s4-date3")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Fondateur;
