import React, { useEffect } from "react";
import video from "../videos/oceanHorizon.mp4";
import logo from "../images/Logo-HYDRAX.png";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie"
const languages = [
  {
    code: "fr",
    name: "Français",
    country_code: "FR",
  },
  {
    code: "en",
    name: "English",
    country_code: "EN",
  },
  {
    code: "sp",
    name: "Spanish",
    country_code: "SP",
  },
  {
    code: "hb",
    name: "hebrew",
    country_code: "עִברִית",
  },
];


function Navbar() {
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
    const { t } = useTranslation();
  useEffect(() => {
    document.body.dir = currentLanguageCode.dir || 'ltr';
  }, [currentLanguage]);
  
  return (
    <>
      <header className="baniere" id="baniere">
        <video
          autoPlay="autoplay"
          muted
          loop="infinite"
          src={video}
          type="video/mp4"
        />
        <div className="logo">
          <a href>
            <img src={logo} alt="" />
          </a>
        </div>
        <div className="baniere-text">
          <p>
            <span>
              {t("accroche1")} <br />
              <div className="accroche2">{t("accroche2")}</div>
            </span>
          </p>
          <br />
          
          <p>{t("main-para")}</p>
        </div>
        <div className="sidebar">
          
        </div>
        <div className="btn-menu">
          <div class="dropdown">
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {languages.map(({ code, name, country_code }) => (
                <ul key={country_code}>
                  <button
                    className="dropdown-item"
                    onClick={() => i18next.changeLanguage(code)}
                    disabled={code === currentLanguageCode}
                  >
                    <span
                      className={`${country_code}mx-2`}
                      style={{
                        opacity: code === currentLanguageCode ? 0.5 : 1,
                      }}
                    ></span>
                    {country_code}
                  </button>
                </ul>
              ))}
            </ul>
          </div>
        </div>
      </header>
    </>
  );
}

export default Navbar;
