import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import photoPA1 from '../images/PONT_AUDMR1.jpg'
import photoPA2 from '../images/PONT_AUDMR2.jpg'
import photoPA3 from '../images/PONT_AUD3.jpg'
import photoPA4 from '../images/CETIM.png'
import video1 from '../videos/turbine.mp4'

function Project() {
  const { t } = useTranslation();
  return (
    <div className="project" id="project">
      <div className="titre-text">
        <p>{t("pe_text1")}</p>
        <p>{t("pe_text2")}</p>
        <p>{t("pe_text3")}</p>
      </div>
      <div className="project">
        <div className="project_cards">
          <img src={photoPA1} alt="" />
          <img src={photoPA2} alt="" />
          <img src={photoPA3} alt="" />
          <img src={photoPA4} alt="" />
        </div>
        <div class="video-para2">
          <video controls="controls" poster="">
            <source src={video1} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );

}

export default Project;