import React from "react";
import videos from "../videos/Video_HYDRAX_2021.mov";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faCrosshairs } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

function Acceuil() {
  const { t } = useTranslation();
  return (
    <section class="nous" id="nous">
      <div class="detail-pres">
        <div class="presentation">
          <h1>{t("acc-title1")}</h1>
          <div class="description">
            <div class="descri-icon">
              <FontAwesomeIcon icon={faBriefcase} />
            </div>
            <div class="descri-text">
              <p>{t("acc-desc1")}</p> <br />
              <p>{t("acc-desc1-1")}</p> <br />
              <p>{t("acc-desc1-2")}</p> <br />
              <p>{t("acc-desc1-3")}</p> <br />
              <p>{t("acc-desc1-4")}</p> <br />
              <p>{t("acc-desc1-5")}</p> <br />
              <p>{t("acc-desc1-6")}</p> <br />
              <p>{t("acc-desc1-7")}</p> <br />
              <p>{t("acc-desc1-8")}</p> <br />
              <p>{t("acc-desc1-9")}</p> <br />
              <p>{t("acc-desc1-10")}</p> <br />
              <p>{t("acc-desc1-11")}</p> <br />
            </div>
          </div>
          <h1>{t("acc-title3")}</h1>
          <div class="description">
            <div class="descri-icon">
              <FontAwesomeIcon icon={faCrosshairs} />
            </div>
            <div class="descri-text">
              <p>{t("acc-desc3")}</p>
              <p>{t("acc-descbis3")}</p>
            </div>
          </div>
          <h1>{t("acc-title4")}</h1>
          <div class="description">
            <div class="descri-text">
              <p>{t("acc-desc4")}</p> <br />
              <p>{t("acc-desc4-1")}</p> <br />
              <p>{t("acc-desc4-2")}</p>
            </div>
          </div>
        </div>
        <div class="video-para">
          <video controls="controls" poster="">
            <source src={videos} type="video/mp4" />
          </video>
        </div>
      </div>
    </section>
  );
}
export default Acceuil;
